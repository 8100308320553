import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import InnerPageHeading from "../components/molecules/InnerPageHeading"
import FreeformTextSection from "../components/organisms/FreeformTextSection"
import DynamicSections from "../components/organisms/DynamicSections"

export default ({
  data: {
    datoCmsInternalPage: {
      title,
      headerImage,
      sections,
      bodyNode: {
        childMarkdownRemark: { html },
      },
      seoMetaTags,
    },
  },
  location,
}) => (
  <>
    <HelmetDatoCms seo={seoMetaTags} />
    <InnerPageHeading headerText={title} image={headerImage && headerImage.image} />
    {html && <FreeformTextSection html={html} />}
    <DynamicSections sections={sections} location={location} />
  </>
)

export const pageQuery = graphql`
  query InternalPage($id: String!) {
    datoCmsInternalPage(id: { eq: $id }) {
      title
      headerImage {
        # 57px is ~4rem
        image: fluid(maxWidth: 1400, maxHeight: 57) {
          ...GatsbyDatoCmsFluid
        }
      }

      bodyNode {
        childMarkdownRemark {
          html
        }
      }

      sections: dynamicSection {
        ... on DatoCmsContactFormSection {
          internal {
            type
          }
          id
          title
          phone: includePhoneNumber
          dateTime: includeDateTime
        }

        ... on DatoCmsImageGridSection {
          internal {
            type
          }
          id
          imageGallery {
            galleryTitle
            titleAlign
            rowMin
            rowMax
            imageGallery {
              caption
              link
              title
              imageFile {
                fluid(maxWidth: 1088) {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
          }
        }

        ... on DatoCmsIconSection {
          internal {
            type
          }
          id
          iconGroup {
            heading
            textNode {
              childMarkdownRemark {
                html
              }
            }
            icon
            linkText
            linkUrl
          }
        }

        ... on DatoCmsPhotoTextSection {
          internal {
            type
          }
          id
          heading
          textNode {
            childMarkdownRemark {
              html
            }
          }
          imageOn
          linkText
          linkURL: linkUrl
          image {
            fluid(maxWidth: 544, imgixParams: { auto: "compress, format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }

        ... on DatoCmsTestimonialsSection {
          internal {
            type
          }
          id
          heading
          testimonialList: testimonial {
            title
            bodyNode {
              childMarkdownRemark {
                html
              }
            }
            cite
          }
        }

        ... on DatoCmsTextSection {
          internal {
            type
          }
          id
          textAlignment
          body: dynamicFreeTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
