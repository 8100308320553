import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const InnerPageHeadingLayout = ({ className, headerText, image }) =>
  image ? (
    <BackgroundImage
      className={className}
      Tag="div"
      fluid={[`linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) `, image]}
    >
      <h1>{headerText}</h1>
    </BackgroundImage>
  ) : (
    <div className={className}>
      <h1>{headerText}</h1>
    </div>
  )

const InnerPageHeading = styled(InnerPageHeadingLayout)`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.dark};
  h1 {
    margin: 2rem auto;
    color: ${({ theme }) => theme.light};
  }
  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    h1 {
      margin: 4rem auto;
    }
  }
`

export default InnerPageHeading
